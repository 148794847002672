var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "LBL0001108",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.process.thirdScenarios,
                columnSetting: false,
                expandAll: false,
                isFullScreen: false,
                usePaging: false,
                hideBottom: true,
                filtering: false,
                editable: _vm.editable && !_vm.disabled && _vm.isSelect,
                selection: "multiple",
                rowKey: "ramThirdAssessScenarioId",
              },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && !_vm.disabled && _vm.isSelect,
                            expression: "editable&&!disabled&&isSelect",
                          },
                        ],
                        attrs: { label: "직접추가", icon: "add" },
                        on: { btnClicked: _vm.addKeyinRiskHazard },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && !_vm.disabled && _vm.isSelect,
                            expression: "editable&&!disabled&&isSelect",
                          },
                        ],
                        attrs: { label: "추가(순회점검)", icon: "add" },
                        on: { btnClicked: _vm.addRiskHazard },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && !_vm.disabled && _vm.isSelect,
                            expression: "editable&&!disabled&&isSelect",
                          },
                        ],
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeRiskHazard },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.editable && !_vm.disabled && _vm.isSelect,
                            expression: "editable&&!disabled&&isSelect",
                          },
                        ],
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.process.thirdScenarios,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveRiskHazard,
                          btnCallback: _vm.saveRiskHazardCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }